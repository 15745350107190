import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactImageMagnify from 'react-image-magnify';


import brush from "../../../assets/images/icons/digiArt.svg";

import CaretRight from "../../../assets/images/icons/CaretRight.svg";
import like2 from "../../../assets/images/icons/like2.svg";
import eye from "../../../assets/images/icons/eye.svg";
import ethereum from "../../../assets/images/icons/ethereum.svg";
import dollar from "../../../assets/images/icons/dollar.svg";
import share from "../../../assets/images/icons/share.svg";
import like3 from "../../../assets/images/icons/like3.svg";


import Goldfish from "../../../assets/images/artCarousel/Goldfish.png";
import artist from "../../../assets/images/artist.png";

import art1 from "../../../assets/images/artItems/art1.png";
import art2 from "../../../assets/images/artItems/art2.png";
import art3 from "../../../assets/images/artItems/art3.png";
import art4 from "../../../assets/images/artItems/art4.png";
import art5 from "../../../assets/images/artItems/art5.png";
import art6 from "../../../assets/images/artItems/art6.png";



import "./PieceDetails.css"

const ChipButton = [
  {
    label: 'Darkness outlet',
  },
  {
    label: 'Possibilities',
  },
  {
    label: 'Psychedelic',
  },
  
]
const settingsTagSlider = {
  dots: false,
  arrows: false,
  infinite: false,
  speed: 1500,
  // slidesToShow: 1,
  centerMode: false,
  slidesToScroll: 2,
  autoplay: false,
  autoplaySpeed: 0,
  zoom: true,
  pauseOnHover: false,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        speed: 2000,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: true,
        arrows: false,
      }
    },
    {
      breakpoint: 480,
      settings: {
        speed: 2000,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: true,
        arrows: false,
        slidesToShow: 1,
        variableWidth: false,
      }
    }
  ]
};

const settingsCategory = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 3500,
  // slidesToShow: 1,
  centerMode: false,
  slidesToScroll: 2,
  autoplay: true,
  autoplaySpeed: 0,
  zoom: true,
  pauseOnHover: true,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        speed: 2000,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 480,
      settings: {
        speed: 2000,
        slidesToScroll: 1,
        slidesToShow: 1,
        variableWidth: false,
      }
    }
  ]
};

const CategoryButton = [
  {
    icon: brush,
    label: 'Pure static digital art',
  },
  
]


const settingsMagnifySlider = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 1500,
  autoplaySpeed: 3500,
  // slidesToShow: 1,
  centerMode: false,
  slidesToScroll: 1,
  autoplay: true,
  zoom: true,
  pauseOnHover: true,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        speed: 2000,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 480,
      settings: {
        speed: 2000,
        slidesToScroll: 1,
        slidesToShow: 1,
        variableWidth: false,
        
      }
    }
  ]
};

const imageSlider = [
  {
    icon: Goldfish,
  },
  {
    icon: Goldfish,
  },
]




const PieceDetails = () => {
  // const {
  //   rimProps
  // } = this.props;
  return (
    <div className="page-main-wrapper art-view-wrapper f-wrp">
      <div className='container'>
        <div className='back-btn-wrp f-wrp'>
          <Link to={'/art-gallery'}><img src={CaretRight} alt='back' /> Back</Link>
        </div>
        <div className='art-main-wrapper f-wrp'>

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className='left-side-wrp f-wrp'>
                <div className='slider-sec MagnifySlider f-wrp'>
                  <Slider {...settingsMagnifySlider}>
                    {imageSlider.map((element, key) => (
                      <div className="each-slider-items f-wrp" key={key}>

                        <ReactImageMagnify {...{
                          enlargedImagePosition: 'over',
                          isHintEnabled: false,
                          hintTextMouse: 'hover to zoom',
                          shouldHideHintAfterFirstActivation: false,
                          smallImage: {
                            alt: 'Wristwatch by Ted Baker London',
                            isFluidWidth: true,
                            src: element.icon,
                            sizes: '(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px'
                          },
                          largeImage: {
                            src: element.icon,
                            width: 1200,
                            height: 1800
                          }
                        }} />
                      </div>
                    ))}
                  </Slider>
                  <div className='art-details f-wrp'>
                    <p><img src={like2} alt='like' /> 368 Likes  &nbsp;&nbsp; <img src={eye} alt='like' /> 4.3k Views</p>
                  </div>
                </div>
                <div className='artist-block f-wrp'>
                  <span><img src={artist} alt='like' /></span>
                  <div className='artist-details'>
                    <h4>Artist name</h4>
                    <p>Sit as close as possible to warm fire without sitting on cold floor taco cat backwards spells taco cat.</p>
                  </div>
                </div>

                <div className='category-slider f-wrp'>
                  <Slider {...settingsCategory}>
                    {CategoryButton.map((element, key) => (
                      <div className="each-categry-btn f-wrp" key={key}>
                        <span className="chip-btn"><img src={element.icon} alt="icon" /> {element.label}</span>
                      </div>
                    ))}
                  </Slider>
                </div>
                <div className='art-tag-btn f-wrp'>
                  
                  <Slider {...settingsTagSlider}>
                    {ChipButton.map((element, key) => (
                      <div key={key}>
                        <span className="chip-btn">{element.label}</span>
                      </div>
                    ))}
                  </Slider>
                </div>

                <div className='image-detail-wrp f-wrp'>
                  <h5>Image detail</h5>
                  <p>Includes printed copy</p>
                  <p><b>Image size</b>4092 x 2895px  692.79 KB</p>
                  <p>Last piece available</p>
                </div>

                <div className='price-detail-sec f-wrp'>
                  <h3><img src={dollar} alt='dollar' /> 23,450 <b>or</b> <img src={ethereum} alt='ethereum' /> 0,0013</h3>
                </div>

                <div className='btn-wrap f-wrp'>
                  <ul>
                    <li><span className="block-btn initial-btn">Work with artist</span></li>
                    <li><span className="block-btn buy-btn">Buy art</span></li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className='right-content-sec f-wrp'>
                <div className='profile-card-wrp f-wrp'>
                  <div className='profile-card-header f-wrp'>
                    <h2>Aqua <span className='share-btn-box'><Button className='icon'><img src={like3} alt='like' /></Button> <Button className='icon'><img src={share} alt='share' /></Button></span></h2>
                    <p>by Mr. Teslabmaker <i>PUB: Mar 6, 2023</i></p>
                  </div>
                  <div className='profile-card-con f-wrp'>
                    <p>Attack feet behind the couch destroy couch flop over give attitude hide when guests come over hopped up on goofballs hunt anything that moves sweet beast under the bed bag stretch shake treat ,  stand in front of the computer screen  chase imaginary bugs flop over why must they do that inspect anything brought into the house  intently sniff hand  chew ipad power cord  leave dead animals as gifts  rub face on everything chase mice, all of a sudden go crazy hopped up on goofballs flop over why must they do that flop over chew ipad power cord  give attitude hide when guests come over sweet beast under the bed. Hide when guests come over attack feet hopped up on goofballs all of a sudden go crazy inspect anything brought into the house  intently sniff hand  chase mice  stand in front of the computer screen  chase imaginary bugs why must they do that, flop over flop over behind the couch bag stretch hunt anything that moves leave dead animals as gifts  rub face on everything sweet beast under the bed give attitude destroy couch, chew ipad power cord  shake treat  hunt anything that moves give attitude chase imaginary bugs attack feet flop over behind the couch.</p>

                    <Link to={'/art'} className='plan-btn'>Read more</Link>
                  </div>
                </div>

                <div className='art-gallery-wrp f-wrp'>
                  <h5>More by Artist</h5>
                  <ul>
                    <li><span><img src={art1} alt='gallery' /></span></li>
                    <li><span><img src={art2} alt='gallery' /></span></li>
                    <li><span><img src={art3} alt='gallery' /></span></li>
                    <li><span><img src={art4} alt='gallery' /></span></li>
                    <li><span><img src={art5} alt='gallery' /></span></li>
                    <li><span><img src={art6} alt='gallery' /></span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PieceDetails;
