import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
// import FB from "../../assets/images/FB.png";
// import linkedin from "../../assets/images/linkedin.png";
// import twitter from "../../assets/images/twitter.png";
// import { Link } from "react-router-dom";

const Footer = (props) => {
  const location = useLocation();
  const [currentURL, setCurrentURL] = useState('')

  useEffect(() => {
    setCurrentURL(location.pathname);
  }, [location.pathname])


  return (
    <div className={`page-main-wrapper f-wrp ${currentURL === '/art-gallery' ? 'inside-art-wrp': 'outside-art-wrp'} ${currentURL === '/art' ? 'inside-art-wrp': 'outside-art-wrp'} ${currentURL === '/view-artist' ? 'inside-art-wrp': 'outside-art-wrp'}`}>
      <div className="container-fluid">
        <div className="container">
          <div className="footer-sec-wrp blk-txt-wrp f-wrp">
            <div className="copy-txt-wrp f-wrp">
              <div className="row">
                <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                  <div className="social-list-wrp f-wrp">
                    <ul className="list-inline">
                      {/* <li className="list-inline-item">
                        <a href={"#"}>
                          <img alt={"no Imag"} src={FB} />
                        </a>
                      </li> */}
                      {/* <li className="list-inline-item">
                        <a href={"#"}>
                          <img alt={"no Imag"} src={linkedin} />
                        </a>
                      </li> */}
                      {/* <li className="list-inline-item">
                        <a href={"#"}>
                          <img alt={"no Imag"} src={twitter} />
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-10 col-xs-10 footer-sep">
                  <p className="else">
                    <b style={{ display: 'inline-block', cursor: 'pointer', fontWeight: 'inherit' }} onClick={() => window.location = 'mailto:hello@mihapp.co'}>hello@mihapp.co</b> &nbsp;|&nbsp; Beverly Hills, CA, USA
                  </p>
                  <p className="art">
                    <b style={{ display: 'inline-block', cursor: 'pointer', fontWeight: 'inherit' }} onClick={() => window.location = 'mailto:hello@mihapp.co'}>hello@mihapp.co</b> &nbsp;|&nbsp; Beverly Hills, CA, USA &nbsp;|&nbsp; Dubai, UAE</p>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                  <span>© 2023 by Mihapp!</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
