import React from "react";

const ThankScreen = () => {
  return (
    <div className='h-100'>
      <h1 className='text-dark align-items-center justify-content-center d-flex w-100 h-100'>
        {" "}
        Thanks, we'll get back to you soon!
      </h1>
    </div>
  );
};

export default ThankScreen;
