import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from "../../assets/images/Logo.png";

const Header = () => {

  const location = useLocation();
  const [currentURL, setCurrentURL] = useState('')

  const [show, setShow] = useState(false);
  const showDropdown = (e) => {
    setShow(!show);
  }
  const hideDropdown = e => {
    setShow(false);
  }

  const [showDrop, setShowDrop] = useState(false);
  const showDropbox = (e) => {
    setShowDrop(!showDrop);
  }
  const hideDropbox = e => {
    setShowDrop(false);
  }

  useEffect(() => {
    setCurrentURL(location.pathname); // to set the current url
  }, [location.pathname])

  const dropDownItems = [
    {
      label: 'See all',
      link: '/projects'
    },
    {
      label: 'Blockchain & NFTs',
      link: '/projects/teslabots'
    },
    {
      label: 'Beauty & wellness',
      link: '/projects/shades'
    },
    {
      label: 'Home remodeling',
      link: '/projects/anyhnb'
    },
    {
      label: 'Education & Children',
      link: '/projects/education'
    },
    {
      label: 'Luxury cars',
      link: '/projects/grace'
    },
    {
      label: 'Mental health',
      link: '/projects/health'
    },
    {
      label: 'Renewables',
      link: '/projects/renewables'
    },
    {
      label: 'Real Estate',
      link: '/projects/real-estate'
    },
    {
      label: 'Education',
      link: '/projects/educations'
    },
    {
      label: 'Financial services',
      link: '/projects/financial-services'
    },
    {
      label: 'Education platform',
      link: '/projects/education-platform'
    },
  ]

  const facilitySlug = window.location.pathname.split('/')[1];

  return (
    <div className={`page-main-header f-wrp ${currentURL === '/' ? 'inside-header-wrp' : 'outside-home-wrp'} ${facilitySlug === 'start-my-project' ? 'contact-wrp-header' : null}`}>
      <Navbar collapseOnSelect expand="lg" className="pc-menu" variant="dark">
        <div className="container">
          <div className='hover-wrp f-wrp' onMouseEnter={showDropbox}
            onMouseLeave={hideDropbox} style={{ width: 'auto' }}>
            <Navbar.Brand href="/" className="navbar-brand">
              <img className="LogoImg" src={Logo} alt="Mihapp" />
            </Navbar.Brand>
            
            <div className={`PC-dropdown ${showDrop === true ? "open" : "close"}`} id="mihapp-popover">
              <ul>
                <li><Link to={"/"}>Home</Link></li>
                <li className='dropDown'><Link to={"/projects"}>portfolio</Link>
                  <div className='dropDown-wrp'>
                    <ul className='dropDownMenu'>
                      {dropDownItems.map((element, key) => (
                        <li key={key}>
                          <Link to={element.link}>{element.label}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </li>
                <li><Link to={"/art-gallery"}>d. art gallery</Link></li>
                <li><Link to={"/about-us"}>about us</Link></li>
                <li><Link to={"/start-my-project"}>contact</Link></li>
              </ul>
            </div>
          </div>

          <div class="justify-content-end navbar-collapse collapse" id="responsive-navbar-nav">
            <div class="navbar-nav">
              <div role="group" class={`nav-item dropdown btn-group ${show ? "show" : "hide"} `} onMouseEnter={showDropdown} onMouseLeave={hideDropdown} >
                <a id="collasible-nav-dropdown" aria-expanded="false" role="button" class="dropdown-toggle nav-link" tabindex="0" href="/projects" to="/projects">portfolio</a>
                <div data-bs-popper="static" class={`dropdown-menu ${show ? "show" : "hide"}`} aria-labelledby="collasible-nav-dropdown">
                  {dropDownItems.map((element, key) => (
                    <a key={key} href={element.link} data-rr-ui-dropdown-item="" class="dropdown-item">{element.label}</a>
                  ))}
                </div>
              </div>
              <a href="/about-us" data-rr-ui-event-key="/about-us" class="nav-link">about us</a>
              <a href="/art-gallery" data-rr-ui-event-key="/art-gallery" class="nav-link">d. art gallery</a>
              <a href="/start-my-project" data-rr-ui-event-key="/start-my-project" class="nav-link">contact</a>
            </div>
          </div>


        </div>
      </Navbar>

      <Navbar collapseOnSelect expand="lg" className="mobile-menu" variant="dark">
        <div className="container-fluid">
          {/* <Navbar.Brand href="/"><img className="LogoImg" src={Logo} alt="Mihapp" /></Navbar.Brand> */}
          <Navbar.Toggle aria-controls="responsive-navbar-nav"><img className="LogoImg mob-logo" src={Logo} alt="Mihapp" /> </Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
            <div className="navhead">
              {/* <Navbar.Brand href="/">Mi <br />
                Happ.</Navbar.Brand> */}
              <Navbar.Toggle aria-controls="responsive-navbar-nav"><img className="LogoImg mob-logo" src={Logo} alt="Mihapp" /></Navbar.Toggle>
            </div>
            <Nav>
              <Nav.Link href="/">home</Nav.Link>
              <NavDropdown title="portfolio" id="collasible-nav-dropdown">
                {dropDownItems.map((element, key) => (
                  <NavDropdown.Item href={element.link} key={key}>{element.label}</NavDropdown.Item>
                ))}
              </NavDropdown>
              <Nav.Link href="/about-us">about us</Nav.Link>
              <Nav.Link href="/art-gallery">d. art gallery</Nav.Link>
              <Nav.Link href="/start-my-project">contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </div>
  );
};

export default Header;
